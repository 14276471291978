<script>
	function didClickLearnMoreLink(e) {
		e.preventDefault();
		e.stopPropagation();

		Calculator.hide();
		setTimeout(openRoute, 250, "/backyard/faq");
	}
</script>

<div class="ice">
	<h1 class="type-36 m-type-24">We handle everything.</h1>

	<p class="type-18 m-type-15 color-text-60">
		Getting Backyard is easy. After you configure your unit, you won’t have
		to worry about filing permits, finding contractors, or any other part of
		the building process.
	</p>

	<div class="spacer-36 spacer-24"></div>

	<div class="lists">
		<h2 class="type-18 m-type-15">
			The base installation estimate includes:
		</h2>

		<ul>
			<li class="type-15 m-type-14">Project management</li>
			<li class="type-15 m-type-14">Permits and surveying</li>
			<li class="type-15 m-type-14">Site prep</li>
			<li class="type-15 m-type-14">Foundation installation</li>
			<li class="type-15 m-type-14">Utility trenching and hookup</li>
			<li class="type-15 m-type-14">Unit shipping and crane-in</li>
			<li class="type-15 m-type-14">Stair and deck installation</li>
			<li class="type-15 m-type-14">Touch up and final cleaning</li>
		</ul>
	</div>

	{#if document.body.classList.contains("hello") === false}
		<div class="spacer-72 m-spacer-32"></div>
		<div class="bottom ice-faq-link m-type-14">
			<a href="/backyard/faq" on:click={didClickLearnMoreLink}>
				Learn more about pricing and construction
			</a>
		</div>
	{/if}
</div>

<style>
	:global(.hello .ice-faq-link) {
		display: none;
	}

	.lists {
		margin: 0 -14px;
	}
</style>
