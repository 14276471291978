<script>
	import { afterUpdate } from "svelte";
	import { formatCurrency } from "../../configurator/common/format.js";
	import calculator, { mortgageForCost } from "../stores/calculator.js";
	import { costForConfiguration } from "../../configurator/stores/cost.js";

	export let model;
	export let cost;

	let previousMortgage = { monthly: 0 };

	$: mortgage =
		$calculator.invalidValues.length === 0
			? mortgageForCost(
					cost || costForConfiguration({ layout: model }).total,
					$calculator
				)
			: previousMortgage;

	afterUpdate(() => {
		previousMortgage = mortgage;
	});

	function didClickReserveButton(e) {
		window.Calculator.hide();

		if (document.body.classList.contains("hello") === true) {
			e.preventDefault();
			e.stopPropagation();
			window.getQuickEstimate(e);
			gtag("event", "calculator_reserve");
		} else {
			gtag("event", "calculator_configure");
		}
	}

	function didClickLearnMoreLink(e) {
		if (document.body.classList.contains("hello") === true) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();
		window.Calculator.hide();
		setTimeout(openRoute, 300, "/backyard/financing");
	}
</script>

<div class="result">
	<div class="mortgage">
		<div class="spacer-8"></div>

		<div class="monthly">
			<div class="type-42 m-type-36">
				{formatCurrency(mortgage.monthly)}<span class="color-text-40">
					/mo
				</span>&thinsp;
			</div>

			<div class="spacer-8"></div>
			<span class="total type-14 color-text-60">
				{formatCurrency(mortgage.total)} total mortgage
			</span>
		</div>

		<div class="extra type-14 color-text-40">
			Tax and insurance not included
		</div>
	</div>
	<div class="spacer-72 m-spacer-50"></div>
	<a
		href={`/backyard/configure#bonewhite+${model}`}
		class="button-rounded button-rounded-blue button-rounded-12 type-18"
		on:click={didClickReserveButton}>
		{#if document.body.classList.contains("hello")}
			{window.experimentValueFor(
				"hello-cta-october-24",
				"cta",
				"Get a quick estimate"
			)}
		{:else}
			Design yours
		{/if}
	</a>
	<div class="spacer-24"></div>
	<div class="type-center">
		<a
			on:click={didClickLearnMoreLink}
			class="type-18 m-type-15"
			href="https://www.samara.com/backyard/financing">
			Learn more about Financing for Backyard
		</a>
	</div>
	<div class="spacer-50"></div>
	<div class="type-14 color-text-40">
		Rates shown are based on the information you provided and are for
		estimation only. Available rates, terms, and product offerings will be
		based on your unique financial situation.
	</div>
</div>

<style>
	:global(.configurator .result .button-rounded) {
		display: none;
	}

	.result {
		display: flex;
		flex-direction: column;
	}

	.mortgage {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		gap: 4px;
		border-top: rgba(0, 0, 0, 0.1) 1px solid;
	}

	.monthly div {
		display: inline;
	}

	@media (max-width: 768px) {
		.monthly div {
			display: block;
		}
	}

	.button-rounded {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		height: 60px;
		white-space: nowrap;
	}
</style>
